import { toast } from "react-toastify";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, firestore } from "../../config/firebase";

export const setLoading = (val: boolean) => async (dispatch: any) => {
  dispatch({
    type: "AUTH_LOADING",
    payload: val,
  });
};

export const getLoginData = (id: any) => async (dispatch: any) => {
  onSnapshot(doc(firestore, "sub_exhibitors", id), (doc: any) => {
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: { docId: doc.id, ...doc.data() },
    });
    dispatch(setLoading(false));
  });
};

export const login = (creds: any, onSuccess: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    signInWithEmailAndPassword(auth, creds.email, creds.password)
      .then((data) => {
        try {
          dispatch(getLoginData(data.user.uid));
          localStorage.setItem("exhibitorId", data.user.uid);
          onSuccess();
        } catch (e: any) {
          dispatch({
            type: "LOGIN_FAIL",
          });
          dispatch(setLoading(false));
          toast.warning(e.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
        });
        dispatch(setLoading(false));
        toast.warning(error.message);
      });
  };
};

export const logout = () => {
  return (dispatch: any) => {
    signOut(auth)
      .then((data) => {
        dispatch({
          type: "LOGOUT_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
        });
        toast.warning(error.message);
      });
  };
};
