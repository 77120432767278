import React, { useEffect, useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Box,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import RotateIcon from "../../svgs/RotateIcon";
import {
  IconCaretDownFilled,
  IconCheck,
  IconChevronDown,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import SortIcon from "../../svgs/SortIcon";
import SearchIcon from "../../svgs/SearchIcon";
import CustomButton from "../../components/buttons/CustomButton";
import VisitorTable from "./VisitorTable";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVisitors,
  inviteVisitor,
} from "../../redux/actions/visitors.actions";
import TotalVisitors from "../dashboard/TotalVisitors";
import BadgesStatus from "../dashboard/BadgesStats";
import VettingStatus from "../dashboard/VettingStatus";
import FormInput from "../../components/form/FormInput";
import { toast } from "react-toastify";
import { useSendEmail } from "../../utils/wds.api";

function Visitors() {
  const dispatch: any = useDispatch();
  const { visitors } = useSelector((state: any) => state.visitors);
  const { superExhibitor } = useSelector((state: any) => state.exhibitors);

  const sendEmail = useSendEmail();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All Visitors");
  const [payload, setPayload] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    const exhibitorId = localStorage.getItem("exhibitorId");
    dispatch(getAllVisitors(exhibitorId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CustomCard>
        <Flex align="center" justify="space-between">
          <Flex flexDir="column" w="100%">
            <CustomText text="Visitors" size="18px" color="yess.base_black" />
            <Flex gap="8px" align="center">
              <CustomText
                text="Last synced: Sept 15, 2024 10:30 AM"
                size="12px"
              />
              <Flex
                align="center"
                gap="6px"
                px="8px"
                h="24px"
                w="89px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Sync Data"
                  size="12px"
                  color="yess.secondary_blue"
                />
                <RotateIcon />
              </Flex>
              <Flex
                align="center"
                gap="12px"
                px="8px"
                h="24px"
                w="112px"
                bg="yess.secondary_light_gray"
                borderRadius="5px"
              >
                <CustomText
                  text="Every 10 mins"
                  size="12px"
                  color="yess.nuetral900"
                />
                <IconChevronDown size="12px" color="#000000" />
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" gap="10px" w="100%" justify="end">
            <Flex
              ps="6px"
              pe="16px"
              py="8px"
              height="48px"
              maxW="360px"
              w="100%"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.border"
              bg="yess.secondary_light_gray"
              align="center"
              gap="8px"
            >
              <Flex
                minH="28px"
                minW="28px"
                borderRadius="4px"
                bg="#fff"
                align="center"
                justify="center"
              >
                <SortIcon />
              </Flex>
              <Input
                placeholder="Search..."
                color="yess.neutral900"
                fontSize="12px"
                fontWeight="400"
                pl="0px"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="aeonik-regular"
                _placeholder={{
                  color: "yess.neutral700",
                }}
                bg="transparent"
                border="none"
                _focus={{ border: "none" }}
                _hover={{ border: "none" }}
                _focusVisible={{ border: "none" }}
              />
              <SearchIcon />
            </Flex>
            <Box pos="relative">
              <CustomButton
                btnTitle={selectedFilter}
                size="12px"
                weight="400"
                color="yess.neutral800"
                h="48px"
                bg="yess.secondary_light_gray"
                radius="12px"
                border="1px solid"
                borderColor="yess.secondary_light_gray"
                maxW="124px"
                rightIco={<IconCaretDownFilled size="12px" color="#4E4949" />}
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              />
              <OutsideClickHandler
                isOpen={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
              >
                <Flex
                  borderRadius="12px"
                  p="12px"
                  flexDir="column"
                  gap="12px"
                  bg="#f2f2f2"
                  pos="absolute"
                  mt="5px"
                  left="0"
                  ml="-20px"
                  w="130px"
                >
                  {[
                    "All Visitors",
                    "Media",
                    "Contractor",
                    "University",
                    "Defence",
                  ]?.map((filter, idx) => (
                    <Flex
                      key={idx}
                      align="center"
                      justify="space-between"
                      h="32px"
                      ps="12px"
                      pe="6px"
                      cursor="pointer"
                      onClick={() => {
                        setSelectedFilter(filter);
                        setIsFilterOpen(false);
                      }}
                    >
                      <CustomText
                        text={filter}
                        size="12px"
                        color="yess.neutral600"
                      />
                      {selectedFilter === filter && (
                        <IconCheck size="16px" color="#0B0A0A" />
                      )}
                    </Flex>
                  ))}
                </Flex>
              </OutsideClickHandler>
            </Box>
            <CustomButton
              btnTitle="Invite Visitor"
              size="12px"
              weight="400"
              color="#fff"
              h="48px"
              bg="yess.secondary_gold"
              radius="12px"
              border="1px solid"
              borderColor="yess.secondary_gold"
              maxW="155px"
              leftIco={<IconPlus color="#fff" size="14px" />}
              onClick={onOpen}
            />{" "}
          </Flex>
        </Flex>
        <VisitorTable
          visitors={visitors}
          search={search}
          filter={selectedFilter}
        />
      </CustomCard>
      <Flex mt="24px" gap="24px">
        <TotalVisitors />
        <BadgesStatus />
        <VettingStatus />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="600px" borderRadius="16px">
          <Flex
            p="24px"
            align="center"
            justify="space-between"
            borderBottom="1px solid #CFCECE"
          >
            <CustomText
              text="Invite Visitor"
              color="yess.base_black"
              size="20px"
            />
            <IconX
              color="#000"
              onClick={() => {
                onClose();
              }}
            />
          </Flex>
          <Flex flexDir="column" gap="24px" p="24px">
            <Flex flexDir="column" gap="11px">
              <CustomText text="Name" size="14px" color="yess.neutral400" />
              <FormInput
                value={payload?.name}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    name: e.target.value,
                  });
                }}
                h="48px"
                ph="Enter Visitor Name"
              />
            </Flex>
            <Flex flexDir="column" gap="11px">
              <CustomText text="Email" size="14px" color="yess.neutral400" />
              <FormInput
                value={payload?.email}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    email: e.target.value,
                  });
                }}
                h="48px"
                ph="Enter Visitor Email"
              />
            </Flex>
            <Flex justify="end">
              <CustomButton
                btnTitle="Invite"
                bg="yess.secondary_gold"
                weight="400"
                onClick={() => {
                  if (
                    Number(superExhibitor?.assignedUsers) <
                    Number(superExhibitor?.utilizedBadges)
                  ) {
                    toast.error(
                      "You have utilized all your quota, please contact admin to get more badges"
                    );
                    return;
                  }
                  dispatch(
                    inviteVisitor(
                      {
                        personalInfo: payload,
                        exhibitorId: localStorage.getItem("exhibitorId"),
                        status: "Pending",
                        badgeAssigned: false,
                      },
                      "Qwertyuiop@123",
                      () => {
                        sendEmail
                          .mutateAsync({
                            to: [payload?.email],
                            from: "noreply@yess.com",
                            subject: `Registration Successfull! Here's Your Temporary Login Information`,
                            html: `<div style='font-family: Arial, sans-serif; color: #333;'> <h1 style='text-align: center; color: #003366;'>You're Invited to the World Defense Show 2025!</h1> <p style='text-align: center; font-size: 18px;'>We are excited to invite you to join the World Defense Show 2025! This is a unique opportunity to connect with industry leaders and experience cutting-edge defense technologies. Use the temporary login credentials below to access your account and complete your registration:</p> <p style='text-align: center; font-size: 16px;'><strong>Email:</strong> ${payload?.email}  <br/><strong>Temporary Password:</strong> Qwertyuiop@123</p> <h3>Next Steps:</h3> <ul style='margin-left: 20px;'> <li>Log in to our visitor portal using the credentials provided above.</li> <li>Complete your profile and provide the necessary information to reserve your seat.</li> <li>Save the date: <strong>5th - 8th February 2025</strong> for the World Defense Show.</li> </ul> <p style='margin-top: 20px; text-align: center;'>For any further assistance, feel free to contact our team.</p> <footer style='text-align: center; margin-top: 40px;'> <p>Kind regards,</p> <p>The World Defense Show Team</p> </footer> </div>`,
                            body: "",
                          })
                          .then((result) => {});
                        onClose();
                        setPayload({
                          name: "",
                          email: "",
                        });
                      }
                    )
                  );
                }}
              />
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Visitors;
