import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import CustomInput from "../../components/form/CustomInput";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import CustomButton from "../../components/buttons/CustomButton";
import Logo from "../../assets/images/white-logo.svg";
import WdsLogo from "../../assets/images/wds-logo.png";
import BgImg from "../../assets/images/auth-bg.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../redux/actions/auth.actions";

function Login() {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();
  const toast = useToast();

  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleLogin = () => {
    if (!payload?.email && !payload?.password) {
      setErrors({
        password: "Password is required",
        email: "Email is required",
      });
      toast({
        title: "Fields are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    dispatch(
      login(payload, () => {
        navigate("/invite-visitors");
      })
    );
  };

  return (
    <Flex
      minH="100vh"
      py="20px"
      px="150px"
      flexDir="column"
      gap="50px"
      backgroundImage={`url(${BgImg})`}
      backgroundSize="cover"
      backgroundPosition="top"
      backgroundRepeat="no-repeat"
    >
      <Flex align="center" justify="space-between">
        <Image src={Logo} alt="Logo" w="124px" h="54px" />
        <Image src={WdsLogo} alt="WDS Logo" w="124px" h="54px" />
      </Flex>
      <Flex
        flexDir="column"
        gap="40px"
        borderRadius="24px"
        border="1px solid"
        borderColor="yess.border"
        bg="yess.g1"
        p="56px 40px"
        maxW="480px"
        w="100%"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <Flex flexDir="column">
            <CustomHeading text="CMS Portal Login" />
            <CustomText text="Login to your account!" color="yess.neutral600" />
          </Flex>
          <Flex gap="22px" flexDir="column">
            <Flex flexDir="column">
              <CustomText text="Email" size="12px" />
              <CustomInput
                type="email"
                ph="Enter your email"
                invalid={errors.email}
                value={payload.email}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    email: e.target.value,
                  });
                }}
              />
            </Flex>
            <Flex flexDir="column">
              <CustomText text="Password" size="12px" />
              <CustomInput
                type={isPasswordVisible ? "text" : "password"}
                ph="Enter your password"
                rightIco={
                  !isPasswordVisible ? (
                    <IconEye
                      size="14px"
                      color="#354052"
                      cursor="pointer"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    />
                  ) : (
                    <IconEyeOff
                      size="14px"
                      color="#354052"
                      cursor="pointer"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    />
                  )
                }
                invalid={errors.password}
                value={payload.password}
                onChange={(e: any) => {
                  setPayload({
                    ...payload,
                    password: e.target.value,
                  });
                }}
              />
            </Flex>
            <Box mt="10px">
              <CustomButton
                btnTitle="Login"
                bg="yess.secondary_gold"
                type="submit"
              />
            </Box>
          </Flex>
        </form>
      </Flex>
      <Flex flexDir="column"></Flex>
    </Flex>
  );
}

export default Login;
