import Login from "../pages/auth/Login";
import Badges from "../pages/badges";
import Visitors from "../pages/visitors";
import VisitorDetails from "../pages/visitors/VisitorDetails";

export const routes = [
  {
    path: "login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/invite-visitors",
    component: Visitors,
    isPrivate: true,
  },
  {
    path: "/visitor-details/:id",
    component: VisitorDetails,
    isPrivate: true,
  },
  {
    path: "/badges",
    component: Badges,
    isPrivate: true,
  },
];
