import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ArabiaShow from "../../svgs/ArabiaShow";
import VisitorsIcon from "../../svgs/VisitorsIcon";
import BadgeIcon from "../../svgs/BadgeIcon";
import CustomText from "../text/CustomText";
import { useLocation, useNavigate } from "react-router-dom";

const MAIN_MENU = [
  {
    title: "Invite Visitors",
    path: "/invite-visitors",
    isEnabled: true,
    icon: <VisitorsIcon />,
    activeIcon: <VisitorsIcon color="#D79A2B" />,
  },
  {
    title: "Visitor Badges",
    path: "/badges",
    isEnabled: true,
    icon: <BadgeIcon />,
    activeIcon: <BadgeIcon color="#D79A2B" />,
  },
];

function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [user] = useState(() => {
    const loggedInUser: any = localStorage.getItem("user");
    return JSON.parse(loggedInUser);
  });

  return (
    <Box
      minW="256px"
      borderRight="1px solid"
      borderColor="yess.border"
      bg="#fff"
      minH="100vh"
      className="sidebar"
    >
      <Flex
        borderBottom="1px solid"
        borderColor="yess.border"
        p="20px 24px"
        h="88px"
      >
        <ArabiaShow />
      </Flex>
      <Flex
        flexDir="column"
        gap="12px"
        p="16px"
        maxH="calc(100vh - 88px)"
        overflowY="auto"
      >
        <CustomText
          text="Main Menu"
          size="10px"
          weight="700"
          color="yess.neutral300"
          transform="uppercase"
        />
        <Flex flexDir="column" gap="4px" mt="4px">
          {MAIN_MENU?.filter((menu: any) => {
            if (user?.email === "level01@gmail.com") {
              return menu?.title === "Visitors";
            } else {
              return menu;
            }
          })?.map((item, index) => (
            <Flex
              key={index}
              align="center"
              p="12px 8px"
              gap="8px"
              borderRadius="8px"
              cursor="pointer"
              pos="relative"
              bg={pathname === item.path ? "#F7F7F7" : "transparent"}
              onClick={() => {
                if (item?.isEnabled) navigate(item.path);
              }}
            >
              {pathname === item.path ? item.activeIcon : item.icon}
              <CustomText
                text={item.title}
                size="14px"
                color={
                  pathname === item?.path
                    ? "yess.secondary_gold"
                    : item?.isEnabled
                    ? "yess.neutral800"
                    : "yess.neutral300"
                }
                cursor
              />
              {pathname === item.path && (
                <Box
                  pos="absolute"
                  left="0"
                  ml="-16px"
                  width="6px"
                  height="40px"
                  bg="yess.secondary_gold"
                  borderRadius="0px 10px 10px 0px"
                />
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Sidebar;
