import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, firestore } from "../../config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";

export const getSuperExhibitor = (id: any) => async (dispatch: any) => {
  const docRef = doc(firestore, "exhibitors", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    dispatch({
      type: "FETCH_SUPER_EXHIBITOR",
      payload: { docId: docSnap.id, ...docSnap.data() },
    });
  } else {
    console.log("No such document!");
  }
};

export const getAllExhibitors = (id: any) => async (dispatch: any) => {
  const q = query(
    collection(firestore, "sub_exhibitors"),
    where("exhibitorId", "==", id)
  );
  onSnapshot(q, (querySnapshot) => {
    const exhibitors: any = [];
    querySnapshot.forEach((doc) => {
      exhibitors.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_EXHIBITORS",
      payload: exhibitors,
    });
  });
};

export const setExhibitorLoading = (val: boolean) => async (dispatch: any) => {
  dispatch({
    type: "EXHIBITOR_LOADING",
    payload: val,
  });
};

export const addExhibitor = (obj: any) => async (dispatch: any) => {
  try {
    createUserWithEmailAndPassword(
      auth,
      obj?.companyInfo?.email,
      "Qwertyuiop@123"
    ).then((data) => {
      try {
        setDoc(doc(firestore, "sub_exhibitors", data.user.uid), obj)
          .then((res) => {
            dispatch({
              type: "EXHIBITOR_SUCCESS",
            });
            dispatch(setExhibitorLoading(false));
          })
          .catch((error) => {
            dispatch(setExhibitorLoading(false));
            toast.warning(error.message);
          });
      } catch (e: any) {
        dispatch(setExhibitorLoading(false));
        toast.warning(e.response.message);
      }
    });
  } catch (e) {
    dispatch(setExhibitorLoading(false));
  }
};

export const deleteExhibitor = (id: any) => async (dispatch: any) => {
  try {
    deleteDoc(doc(firestore, "sub_exhibitors", id)).then((res) => {
      return;
    });
  } catch (e) {}
};
