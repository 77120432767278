import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

function AuthLayout() {
  const navigate = useNavigate();
  const { exhibitorId } = useSelector((state: any) => state.user);
  if (exhibitorId) {
    navigate("/invite-visitors");
  }
  return <Outlet />;
}

export default AuthLayout;
